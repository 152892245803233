<template>
  <v-table fixed-header v-if="trainings.length">
    <thead>
    <tr>
      <th class="text-left">&nbsp;</th>
      <th class="text-left" v-for="(training, index) in trainings" :key="`training-header-${index}`">
        {{ training.name }}
      </th>
    </tr>
    </thead>

    <tbody>
    <tr v-for="user in users" :key="user.id">
      <td :title="user.email">
        <v-icon v-if="user.intern && user.afas" class="text-green-lighten-1" title="Geregistreerde gebruiker">mdi-account-check-outline</v-icon>
        <v-icon v-if="!user.intern" class="text-grey-lighten-1" title="Nog niet geregistreerd">mdi-account-cancel-outline</v-icon>
        <v-icon v-if="user.intern && !user.afas" class="text-deep-orange" title="Geen officieel emailadres gebruikt, niet gelinkt">mdi-account-switch-outline</v-icon>
        {{ user.name }}
      </td>

      <td v-for="(progress, index) in user.progress" :key="`${user.id}-${index}}`" :title="trainings[index].name">
        <v-progress-linear v-if="progress === 100" v-model="user.progress[index]" color="light-green" height="26" rounded>
          <strong class="pb-1 text-green-darken-4"><v-icon size="17">mdi-check-circle</v-icon></strong>
        </v-progress-linear>
        <v-progress-linear v-else-if="progress > 0" v-model="user.progress[index]" color="light-blue" height="26" rounded>
          <strong>{{ progress }}%</strong>
        </v-progress-linear>
        <v-progress-linear v-else height="26" rounded />
      </td>
    </tr>
    </tbody>
  </v-table>
</template>

<script>
export default {
  name: 'ProgressTable',

  props: {
    trainings: {
      type: Array,
    },
    users: {
      type: Array,
    }
  },
};
</script>

<style scoped>
</style>