import AxiosApi from '@/services/AxiosApi';
import store from "../store";

const ContextService = {
    async load() {
        let domain = window.location.hostname;

        if ('localhost' === domain) {
            domain = 'https://analytics.raas.g4s.vvcr-prodrive.nl';
        }

        const context = await AxiosApi.get('/context', {
            params: {
                domain: domain,
            }
        });

        await store.dispatch('setContext', context.data);
    },

    getLanguage() {
        return store.getters.language;
    },

    getClientId() {
        return store.getters.clientId;
    },

    getClientSecret() {
        return store.getters.clientSecret;
    }
};

export default ContextService
