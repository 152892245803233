<template>
    <div class="text-center">
        <v-divider></v-divider>
        <img :src="require('../assets/logo.png')" class="header-image" />
    </div>
</template>

<script>
export default {
    name: 'HeaderImage',
};
</script>

<style scoped>
  img.header-image {
    width: 100%;
    max-width: 350px;
    padding: 30px 18px 0 18px;
  }
</style>