export default {
  "global": {
    "my-account-link-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn account"])},
    "logout-action-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
    "my-account-section-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn account"])},
    "account-name-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "account-name-change-action-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wijzigen"])},
    "account-password-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
    "account-password-change-action-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wijzigen"])},
    "new-password-restriction-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het wachtwoord moet minimaal 8 tekens lang zijn en een letter en een cijfer bevatten."])},
    "password-requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het wachtwoord moet minimaal 8 tekens lang zijn en een letter en een cijfer bevatten."])},
    "close-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
    "change-account-name-confirm-action-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigen"])},
    "change-account-name-cancel-action-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regio"])}
  },
  "languages": {
    "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])},
    "be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlaams"])},
    "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frans"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engels"])}
  },
  "regions": {
    "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederland"])},
    "be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["België"])}
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
    "section-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen is alleen mogelijk met een analytics account"])},
    "input-field-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email adres"])},
    "input-field-email-validation-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen geldig email adres"])},
    "input-field-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
    "login-form-submit-action-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
    "password-forgot-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
    "registration-link-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
    "password-reset-section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul je email adres in om je wachtwoord te kunnen resetten. Je ontvangt een nieuw wachtwoord per mail."])},
    "perform-password-reset-action-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord resetten"])},
    "perform-password-reset-cancel-action-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
    "password-reset-confirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Het nieuwe wachtwoord wordt binnen enkele ogenblikken verstuurd naar het email adres ", _interpolate(_named("email"))])},
    "close-password-reset-window-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
    "login-error-email-unvalidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het emailadres is nog niet gevalideerd."])},
    "login-error-invalid-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het opgegeven wachtwoord is onjuist."])},
    "login-error-unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een onbekende fout opgetreden."])}
  },
  "404": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404 pagina niet gevonden…"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen pagina die hoort bij deze url."])},
    "navigate-to-start-link-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik hier om terug te gaan naar de beginpagina."])},
    "navigate-to-start-link-label-secondary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar begin pagina"])}
  }
}