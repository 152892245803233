<template>
  <v-layout>
    <v-app-bar v-if="loggedInUser" color="white">
      <v-img alt="VVCR Prodrive" max-height="44" max-width="300" contain transition="scale-transition" :src="require('@/assets/logo.png')" />
      <v-app-bar-title class="d-none d-sm-flex">{{ organization }}</v-app-bar-title>

      <v-spacer></v-spacer>

      <account-menu v-if="loggedInUser" v-bind:logged-in-user="loggedInUser" @onLogoutClick="logout()" @onShowAccountModalClick="isShowingAccountModal = true" />
    </v-app-bar>

    <router-view />
  </v-layout>
</template>

<script>
import AccountMenu from "@/components/AccountMenu";
import SessionService from "@/services/SessionService";

export default {
  name: 'App',

  components: {
    AccountMenu,
  },

  mounted() {
    window.translate = this.$t.bind(this);
  },

  computed: {
    loggedInUser() {
      return this.$store.state.loggedInUser;
    },
    isAnalyst() {
      return this.$store.getters.isAnalyst;
    },
    organization() {
      return this.$store.getters.organization;
    },
  },

  methods: {
    logout() {
      SessionService.invalidateSession();

      this.$router.push({name: 'login'});
    },
  }
}
</script>

<style lang="scss">
html, body {
  height: 100%;
  overflow: hidden !important;
}
.fill-height {
  height: 100%;
  display: flex;
}
.v-container.fill-height > .row {
  max-width: 100% !important;
  margin-left: 0;
  margin-right: 0;
}
#app {
  background: url('~@/assets/background.jpg') no-repeat center center !important;
  background-size: cover !important;
}
#app, .v-layout {
  height: 100%;
}
.v-main {
  overflow-y: scroll;
}

header.transparent {
  box-shadow: none !important;
}

.v-list-item .v-list-item__subtitle, .v-list-item .v-list-item__title {
  line-height: 3 !important;
}

.vp-outro-wrapper {
  display: none !important;
}

.underline {
  text-decoration: underline;
}

.pr-20 {
  padding-right: 20px;
}
.mt--20 {
  margin-top: -20px;
}
.fs-18 {
  font-size: 18px;
}

.powered-by {
  font-size: 12px;
  opacity: 0.9;
}
.powered-by span {
  display: inline-block;
  padding-bottom: 2px;
}

.v-input--selection-controls .v-input__control {
  width: 100%;
}

@keyframes fadein {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
    opacity:0;
  }
  to {
    opacity: 1;
  }
}
</style>
