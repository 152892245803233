import { createStore } from 'vuex'
import AxiosApi from "@/services/AxiosApi";

export default createStore({
  state: {
    oauth: null,
    loggedInUser: null,
    language: null,
    region: null,
    context: null,
  },
  getters: {
    loggedInUser: (state) => () => {
      return state.loggedInUser;
    },
    oauth: state => {
      return state.oauth;
    },
    language: state => {
      return state.language;
    },
    region: state => {
      return state.region;
    },
    organization: (state) => {
      return state.context.organization;
    },
    clientId: (state) => {
      return state.context.clientId;
    },
    clientSecret: (state) => {
      return state.context.clientSecret;
    },
    isAnalyst: (state) => {
      const user = state.loggedInUser;
      return user && 'roles' in user && (-1 !== user.roles.indexOf('ROLE_ANALYST') || -1 !== user.roles.indexOf('ROLE_ADMIN'));
    },
  },
  mutations: {
    unloadUser(state) {
      state.loggedInUser = null;
    },
    setOauth(state, oauth) {
      state.oauth = oauth;
    },
    setLoggedInUser(state, user) {
      user['sso'] = !user.email.includes('@');
      state.loggedInUser = user;
    },
    setLanguage(state, language) {
      state.language = language;
    },
    setRegion(state, region) {
      state.region = region;
    },
    setContext(state, context) {
      state.context = context;
    },
  },
  actions: {
    unloadUser() {
      this.commit('unloadUser');
    },
    setOAuth(state, oauth) {
      if (oauth && Object.prototype.hasOwnProperty.call(oauth, 'access_token')) {
        AxiosApi.defaults.headers.common['Authorization'] = `Bearer ${oauth.access_token}`;
      }
      this.commit('setOauth', oauth);
    },
    setLoggedInUser(state, user) {
      this.commit('setLoggedInUser', user);
    },
    setLanguage(state, language) {
      this.commit('setLanguage', language);
    },
    setRegion(state, region) {
      this.commit('setRegion', region);
    },
    setContext(state, context) {
      this.commit('setContext', context);
    },
  }
})
