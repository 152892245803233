import { createRouter, createWebHistory } from 'vue-router'
import SessionService from '../services/SessionService'
import UserService from '../services/UserService'
import AnalyticsView from '../views/AnalyticsView.vue'
import LoginView from '../views/LoginView.vue'
import NotFound404View from '../views/NotFound404View.vue'

const routes = [
  {
    path: '/',
    name: 'analytics',
    component: AnalyticsView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      unauthenticated: true,
      authenticated: false
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound404',
    component: NotFound404View,
  }
]

const router = createRouter({
  // eslint-disable-next-line
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticatedRoute = !Object.prototype.hasOwnProperty.call(to.meta, 'authenticated') ? true : to.meta.authenticated;
  const isUnauthenticatedRoute = !Object.prototype.hasOwnProperty.call(to.meta, 'unauthenticated') ? false : to.meta.unauthenticated;

  SessionService.isAuthenticated().then((isAuthenticated) => {
    if (isAuthenticated) {
      if (isUnauthenticatedRoute) {
        // Go to authentication route index
        return next({ name: 'home' });
      }

      if (!UserService.userIsLoaded()) {
        // Load user
        UserService.fetchLoggedInUser();
      }

      next()
    } else {
      // Is not authenticated
      if (isAuthenticatedRoute) {
        // Go to registration
        return next({ name: 'login' })
      }

      next()
    }
  })
})

export default router
