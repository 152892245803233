<template>
  <div v-if="loggedInUser">
    <div>
      <v-icon class="mr-2" size="x-large">mdi-account-circle</v-icon>
      <span class="hidden-xs-only">{{loggedInUser.name}}</span>
      <v-btn v-on:click="onLogoutClick()" class="mx-2" icon>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountMenu',

  props: {
    loggedInUser: Object
  },

  methods: {
    onLogoutClick() {
      this.$emit('onLogoutClick');
    },
    onShowAccountModalClick() {
      this.$emit('onShowAccountModalClick');
    }
  }
}
</script>