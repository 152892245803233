import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import ContextService from './services/ContextService'
import { loadFonts } from './plugins/webfontloader'

// eslint-disable-next-line
(async () => {
    await ContextService.load();

    loadFonts()

    createApp(App)
        .use(router)
        .use(store)
        .use(vuetify)
        .use(i18n)
        .mount('#app')
})();