<template>
  <div class="home">
    <h1>Not found 404</h1>
  </div>
</template>

<script>
export default {
  name: 'NotFound404View',
}
</script>
