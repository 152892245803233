// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
    theme: {
        defaultTheme: 'vvcr',
        themes: {
            vvcr: {
                dark: false,
                colors: {
                    primary: '#00a3e0',
                    // secondary: '#b0bec5',
                    // accent: '#8c9eff',
                    // error: '#b71c1c',
                }
            }
        }
    }
})
