<template>
  <v-main>
    <v-container class="my-6" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12" lg="12" xl="10">
          <v-card class="elevation-12 pv-2 pb-6">
            <v-card-text v-if="isAnalyst" style="max-height: 100%" scrollable class="px-6 overflow-y-auto">
              <div class="d-flex justify-center my-12 pt-12" v-if="isLoading">
                <v-progress-circular
                    :size="120"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
              </div>

              <vue3-chart-js :type="testCountChart.type" :options="testCountChart.options" :data="testCountChartData" v-if="!isLoading" />

              <br />

              <vue3-chart-js :type="slideProgressChart.type" :options="slideProgressChart.options" :data="slideProgressData" v-if="!isLoading" />

              <br />

              <v-row class="mt-12 pb-4 d-flex justify-center align-center" v-if="!isLoading">
                <span class="text-h5 ml-2 mr-12">{{ filteredUsers.length }} gebruikers</span>
                <span class="d-flex flex-fill flex-grow-1"></span>

                <v-select
                    v-if="0 < departments.length"
                    v-model="selectedDepartment"
                    :items="departments"
                    class="mr-6"
                    label="Afdeling"
                    hide-details
                ></v-select>

                <v-checkbox v-model="filter.notStarted" label="Nog niet begonnen" color="info" hide-details></v-checkbox>
                <v-checkbox v-model="filter.inProgress" label="In progress" color="info" hide-details></v-checkbox>
                <v-checkbox v-model="filter.finished" label="Alles afgerond" color="info" hide-details></v-checkbox>
                <v-checkbox v-model="filter.alternativeAccounts" label="Alternatieve accounts" color="info" hide-details></v-checkbox>
              </v-row>

              <ProgressTable :trainings="this.trainings" :users="this.filteredUsers"  />
            </v-card-text>

            <v-card-text v-else style="max-height: 100%" scrollable class="px-6 overflow-y-auto">
              <p>
                This account is not able to view analytics data.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import AxiosApi from "@/services/AxiosApi";
import ProgressTable from '@/components/ProgressTable';
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
  name: 'AnalyticsView',

  components: {
    ProgressTable,
    Vue3ChartJs,
  },

  data () {
    return {
      isLoading: true,
      trainings: [],
      users: [],
      testCountChartData: null,
      slideProgressData: null,
      selectedDepartment: 'Alle',
      filter: {
        notStarted: true,
        inProgress: true,
        finished: true,
        alternativeAccounts: true,
      },
      departments: ['Alle'],
      colors: [
        { r: 26, g: 188, b: 156 },
        { r: 241, g: 196, b: 15 },
        { r: 41, g: 128, b: 185 },
        { r: 52, g: 73, b: 94 },
        { r: 255, g: 99, b: 132 },
        { r: 54, g: 162, b: 235 },
        { r: 255, g: 206, b: 86 },
        { r: 75, g: 192, b: 192 },
        { r: 153, g: 102, b: 255 },
        { r: 255, g: 159, b: 64 },
        { r: 125, g: 26, b: 188 },
        { r: 167, g: 26, b: 188 },
        { r: 188, g: 26, b: 125 },
        { r: 188, g: 26, b: 60 },
        { r: 188, g: 121, b: 26 },
        { r: 188, g: 152, b: 26 },
      ]
    }
  },

  computed: {
    isAnalyst() {
      return this.$store.getters.isAnalyst;
    },
    filteredUsers() {
      return this.users.filter((user) => {
        return (this.filter.alternativeAccounts || user.afas)
            && (this.filter.notStarted || !Object.values(user.progress).every(progress => progress === 0))
            && (this.filter.inProgress || !Object.values(user.progress).some(progress => progress > 0))
            && (this.filter.finished || !Object.values(user.progress).every(progress => progress === 100))
            && ('Alle' === this.selectedDepartment || ('Overig' === this.selectedDepartment ? !user.department : user.department === this.selectedDepartment))
        ;
      });
    }
  },

  created() {
    // eslint-disable-next-line
    AxiosApi.get(`${process.env.VUE_APP_API_URL}/api/analytics-progress`).then((result) => {
      this.isLoading = false;
      this.trainings = result.data.trainings;
      this.users = result.data.users;
      this.departments = [...new Set(result.data.users.filter(user => user.department).map(user => user.department))];

      if (0 < this.departments.length) {
        this.departments = ['Alle', ...this.departments, 'Overig'];
      }

      this.testCountChartData = {
        labels: result.data.trainings.map(training => training.name),
        datasets: [
          {
            label: "Afgerond",
            backgroundColor: this.colors.map(c => `rgba(${c.r},${c.g},${c.b},1)`),
            data: result.data.trainings.map(training => training.testFinishedCount),
            stack: 'Stack 0',
          },
          {
            label: "Actief",
            backgroundColor: this.colors.map(c => `rgba(${c.r},${c.g},${c.b},0.5)`),
            data: result.data.trainings.map(training => training.testInProgressCount),
            stack: 'Stack 0',
          },
        ]
      };
      this.testCountChart.options.scales.y.max = result.data.users.length;

      let maxSlides = Math.max(...result.data.trainings.map(training => training.slideProgress.length));
      let datasets = [];
      let backgroundColor = [];

      for (let i = 0; i < maxSlides; i++) {
        backgroundColor = [];

        result.data.trainings.forEach((training, index) => {
          const percentage = (i + 1) / training.slideProgress.length;
          const c = this.colors[index];
          backgroundColor.push(`rgba(${c.r}, ${c.g}, ${c.b}, ${percentage})`);
        })

        datasets.push({
          label: `Video ${i}`,
          backgroundColor: backgroundColor,
          data: result.data.trainings.map((training) => {
            if (training.slideProgress[i]) {
              if (0 === i) {
                return training.slideProgress[i];
              } else {
                // Relative
                return training.slideProgress[i] - datasets[i - 1].data[result.data.trainings.indexOf(training)];
              }
            }

            return 0;
          }),
          stack: 'Stack 0',
        });
      }

      this.slideProgressData = {
        labels: result.data.trainings.map(training => training.name),
        datasets: datasets,
      };

      this.slideProgressChart.options.scales.y.max = result.data.users.length;
    })
  },

  setup() {
    const testCountChart = {
      type: "bar",
      options: {
        min: 0,
        responsive: true,
        aspectRatio: 3.5,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Actieve versus afgeronde trainingen',
          }
        },
        scales: {
          y: {
            min: 0,
            stacked: true,
          },
        },
      },
    };

    const slideProgressChart = {
      type: "bar",
      options: {
        min: 0,
        responsive: true,
        aspectRatio: 3.5,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Voortgang per training',
          }
        },
        scales: {
          y: {
            min: 0,
            max: 160,
            stacked: true,
          },
        },
      },
    };

    return {
      testCountChart,
      slideProgressChart,
    };
  },


}
</script>
